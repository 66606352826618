const table_fields = [
    {
        fieldname: "min_qty",
        fieldtype: "Float",
        in_list_view: 1,
        label: "Min Qty",
        mandatory: true
    },
    {
        fieldname: "rate",
        fieldtype: "Currency",
        in_list_view: 1,
        label: "Rate",
        mandatory: true
    },
    {
        fieldname: "priority",
        fieldtype: "Int",
        in_list_view: 1,
        label: "Priority",
        read_only: true
    },
    {
        fieldname: "name",
        fieldtype: "Link",
        options: "Pricing Rule",
        in_list_view: 1,
        label: "Pricing Rule",
        read_only: true
    },
];



frappe.ui.form.on('Item', {
    refresh(frm) {
        frm.add_custom_button(__('Staggered Prices'), function () {

            frappe.xcall('label_printing.scaled_pricing.scaled_pricing.get_price_lists', {
                item_code: frm.doc.item_code
            }).then((result) => {
                generate_dialog(result, frm)
            })
        });
    }
})

function generate_dialog(data, frm) {
    let d = new frappe.ui.Dialog({
        title: frm.doc.name,
        fields: [
            {
                fieldname: "scaled_prices",
                fieldtype: "Table",
                label: "Scaled Prices",
                cannot_add_rows: false,
                in_place_edit: true,
                reqd: 0,
                data: data,
                fields: table_fields,
            },
        ],
        size: 'large',
        primary_action_label: 'Submit',
        primary_action(values) {
            if (values.scaled_prices.length > 20) {
                frappe.throw(__('You can add a maximum of 20 Scaled Prices!'))
            } else {
                frappe.xcall('label_printing.scaled_pricing.scaled_pricing.change_price_lists', {
                    values: values,
                    frm: frm.doc.name
                })
                    .then((result) => {
                        d.hide()
                        if (result === 200) {
                            frappe.show_alert({
                                message: __('Price Lists have been modified!'),
                                indicator: 'green'
                            }, 5);
                            d.hide();
                        } else {
                            frappe.throw(__("Couldn't change Pricelists!"))
                        }
                    })
            }
        }
    });

    d.show();
}